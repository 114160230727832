<template>
    <PageHeader :title="title" />

    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="checks['001']">
      <button @click="infoblockShow == true ? infoblockShow = false : infoblock()" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div>

    <infoblocks 
        v-if="infoblockShow" 
        :obj="this.information" 
        style="margin-top:15px;" 
    />

    <!-- компонент таблицы -->
    <tablecustom
        @search="searchB" 
        :dataParams="dataParams"
        :objParams="objParams"
        :columns="columns" 
        :rows="filteredItems"
        :tabs="othertabs"
        :addtabs="true"
        @addCategory="showFilterBox = true"
        @removeCategory="removeCategory"
        @open="open" 
        @changeFilter="changeFilter" 
        @changeTab="changeTab"
    />

    <!-- картка клієнта -->
    <viewcard 
        v-if="showModal == true" 
        @call="call"
        @close="showModal = false" 
        :objcard="objcard"
    ></viewcard>

    <!-- таби -->
    <filterTabs 
        v-if="showFilterBox" 
        @close="showFilterBox = false"
        @changeTabs="changeTabs"
        :othertabs="othertabs"
    >
    </filterTabs>

    <cardRegist v-if="this.showCard == true"></cardRegist>
</template>

<script>
import PageHeader from "@/components/page-header";
import tablecustom from '@/components/globaltable/index';
import viewcard from '../../accounts/allAccounts/viewnew/index';
import filterTabs from './filterTabs/index.vue'
import infoblocks from '@/components/info/blocks'
import { SuperAdmin } from '@/API.js';
import { storeS } from "@/store";
import { getFlag, mutateCommentsAcc } from '@/usabilityScripts/globalMutate.js'
import cardRegist from './view/index.vue'

let apiServe = new SuperAdmin();

export default {
    components: {
        PageHeader,
        tablecustom,
        viewcard,
        filterTabs,
        infoblocks,
        cardRegist
    },
    data() {
        return {
            showCard: false,
            title: this.$t('AllAccounts'),
            search: '',
            showFilterBox: false,
            othertabs: [],
            selectCountry: '',
            showModal: false,
            infoblockShow: false,
            dataParams: {
                status: true,
                page: "AllAccounts"
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                pr1: '',
                pr2: '',
            },
            rows: [],
            objPages: {},
            objcard: {},
            columns: [
                {
                    name: this.$t('AccountID'),
                    text: "account_id",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t("country"),
                    text: "country",
                    align: "left",
                    status: true,
                    mutate: (item) => this.getFlag(item.title)
                },
                {
                    name: this.$t("phone_num"),
                    text: "phone",
                    align: "right",
                    status: true
                },
                {
                    name: this.$t("date_time"),
                    text: "register_datetime",
                    align: "right",
                    status: true
                },
                {
                    name: this.$t("Comment"),
                    text: "regist_status",
                    align: "right",
                    status: true,
                    mutate: (item, obj) => this.mutateComments(item, obj)
                },
            ],
            rows: [],
            objcard: {},
            information: [
                {
                    title: this.$t('Total'),
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "info",
                    sum: "0"
                },
                {
                    title: 'Україна',
                    value: "0",
                    icon: "las la-file-invoice",
                    color: "success",
                    sum: "0"
                },
                // {
                //     title: this.$t('notSuccessful'),
                //     value: "0",
                //     icon: "las la-file-invoice",
                //     color: "warning",
                //     sum: "0"
                // },
            ],
        };
    },
    created() {
        this.getdata();
    },
    methods: {
        getdata(s){
            // phone accountId email
            apiServe.getRegisterList(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.registrations
                }
            })
        },
        call(e) {
            
            localStorage.setItem('actualCall', e)
            this.eventBus.emit('actualCall', {
                    status: true
                })
        },
        open: function(e){
            apiServe.getCard(e.phone).then(result => {
                if(result.status == 'done') {
                    this.objcard = result.data
                    this.showModal = true
                } else {
                    this.$toast.error(this.$t('error'))
                }
            })

            // this.showCard = true
        },
        infoblock(){
            this.information[0].value = this.filteredItems.length
            this.information[1].value = this.filteredItems.filter(item => item.country_title == 'Ukraine').length
            
            this.information[0].sum = this.filteredItems.filter(item => item.action != '').length
            this.information[1].sum = this.filteredItems.filter(item => item.country_title == 'Ukraine' && item.action != '').length
            this.infoblockShow = true
        },
        changeFilter(name, value){
            if(name == 'all'){
                this.objParams.pr1 = '';
                this.objParams.pr2 = '';
                this.objParams.search = '';
                this.selectCountry = '';
                this.getdata()
            } else if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else if (name == 'countryFilter') {
                    // var arrCountry = [];
                    // arrCountry.push(value)
                    this.objParams[name] = ['UA'];
                    
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
        searchB(e){
            this.objParams.search = e
            this.getdata()
        },
        getFlag(e){
            return getFlag(e)
        },
        mutateComments(e, obj){
            return mutateCommentsAcc(e, obj)
        },
        changeTabs(){
            localStorage.setItem("Registrationscheckbox", JSON.stringify(this.othertabs));
            localStorage.setItem("resultRegistrations", this.othertabs.length)
        },
        removeCategory(e, i){
            this.othertabs.splice(i, 1)
            localStorage.setItem("Registrationscheckbox", JSON.stringify(this.othertabs));
        },
        changeTab(name, value){
            console.log('name', name);
            console.log('value', value);
            if (Array.isArray( value ) == false) {
                if(name == 'country'){
                    this.selectCountry = value
                }
                if(name == 'pr1') {
                    let today = String(new Date()).split(' ');
                    if(value == 'today'){
                        this.objParams.pr1 = today[3]+'-'+this.mounthMutate(today[1])+'-'+today[2]
                        this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+today[2]
                        this.getdata()
                    } else if(value == 'yesterday'){
                        this.objParams.pr1 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2])-1)
                        this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2])-1)
                        this.getdata()
                    } else if(value == 'month'){
                        var d = new Date();
                        d.setMonth(d.getMonth() - 1);
                        var newDate = String(new Date(d)).split(' ');
                        this.objParams.pr1 = newDate[3]+'-'+this.mounthMutate(newDate[1])+'-'+String(Number(newDate[2])-1)
                        this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+today[2]
                        this.getdata()
                    } else if(value == 'threeMonths'){
                        var d = new Date();
                        d.setMonth(d.getMonth() - 3);
                        var newDate = String(new Date(d)).split(' ');
                        this.objParams.pr1 = newDate[3]+'-'+this.mounthMutate(newDate[1])+'-'+String(Number(newDate[2])-1)
                        this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+today[2]
                        this.getdata()
                    } else if(value == 'halfYear'){
                        var d = new Date();
                        d.setMonth(d.getMonth() - 6);
                        var newDate = String(new Date(d)).split(' ');
                        this.objParams.pr1 = newDate[3]+'-'+this.mounthMutate(newDate[1])+'-'+String(Number(newDate[2])-1)
                        this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+today[2]
                        this.getdata()
                    } else if(value == 'year'){
                        var d = new Date();
                        d.setMonth(d.getMonth() - 12);
                        var newDate = String(new Date(d)).split(' ');
                        this.objParams.pr1 = newDate[3]+'-'+this.mounthMutate(newDate[1])+'-'+String(Number(newDate[2])-1)
                        this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+today[2]
                        this.getdata()
                    }
                    return
                }
            }
        },
        mounthMutate(e) {
            var c = ''
            switch (e) {
                case 'Feb':
                    c = '2'
                break;
                case 'Mar':
                    c = '3'
                break;
                case 'Apr':
                    c = '4'
                break;
                case 'May':
                    c = '5'
                break;
                case 'Jun':
                    c = '6'
                break;
                case 'Jul':
                    c = '7'
                break;
                case 'Aug':
                    c = '8'
                break;
                case 'Sep':
                    c = '9'
                break;
                case 'Oct':
                    c = '10'
                break;
                case 'Nov':
                    c = '11'
                break;
                case 'Dec':
                    c = '12'
                break;
                case 'Jan':
                    c = '1'
                break;
            }
            return c
        },
    },
    computed: {
        user() {
            return storeS.userbase
        },
        perms() {
            return storeS.perms
        },
        checks() {
            return storeS.checks
        },
        filteredItems: function() {
            return Object.values(this.rows)
                // По країні
                .filter(item => {
                    return this.selectCountry == 0 || item.country.alpha2 == this.selectCountry;
                })
        },
    },
    mounted() {
        this.othertabs = localStorage.getItem("Registrationscheckbox") ? JSON.parse(localStorage.getItem("Registrationscheckbox")) : [];
    }
};
</script>

<style scoped>
.tr_etner:hover {
cursor: pointer;
background: #f3f3f9;
}
</style>
