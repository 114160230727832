<template>
    <b-card>
        <div class="card-header">
            <h5 class="card-title mb-0"><b>{{ $t('aboutApplication') }}</b></h5>
        </div>
        <div class="card-body">
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('phone_number') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `" @click="getClient">{{ form.userPhone }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('priority') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy ${this.mutatePriority(form.priority).bg}`" >{{ this.mutatePriority(form.priority).name }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="form.createWorkerName">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('created') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `" @click="copy(this.form.createWorkerName)">{{ form.createWorkerName }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="form.createDatetime">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('createDate') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`fs-14 mb-0 fw-normal copy `" @click="copy(this.form.createDatetime)">{{ form.createDatetime }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="form.createComment">
                <div class="d-flex mt-4">
                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                        <div :class="`avatar-title bg-info rounded-circle fs-16 text-white`" >
                            <i :class="`ri-hashtag`"></i>
                        </div>
                    </div>
                    <div :class="`flex-grow-1 overflow-hidden`" >
                        <p class="mb-1 fs-16">{{ $t('Comment') }} :</p>
                        <div class="ctext-wrap" style="display:flex">
                            <div class="ctext-wrap-content">
                                <h6 :class="`mb-0 fs-16 `" @click="copy(this.form.createComment)">{{ form.createComment }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-card>
</template>

<script>
import { mutatePriority } from '@/usabilityScripts/globalMutate.js'

export default{
    props: ['obj'],
    data(){
        return {
            form: ''
        }
    },
    created(){
        this.form = this.obj
    },
    methods: {
        getClient() {
            this.eventBus.emit('getClient', {status: true, number: this.form.userPhone, form: this.form})
        },
        copy(item){
            // Перевіряємо чи то Сафарі (робиться копіювання через API ClipBoardItem та асинхронні операції)
            if(window.navigator.userAgent.indexOf('Version') != -1 && window.navigator.userAgent.indexOf('Safari') != -1) {
                async function someAsyncMethod() {
                    return new Promise((resolve) => {
                        setTimeout(() => {
                            resolve('resolved');
                        }, 10);
                    }); // та сама асинхронна операція для розгону
                }
                const clipboardItem = new ClipboardItem({
                    'text/html': someAsyncMethod().then((result) => { // копіюємо html, щоб зберегти стилі тексту

                    /**
                     * We have to return an empty string to the clipboard if something bad happens, otherwise the
                     * return type for the ClipBoardItem is incorrect.
                     */
                    if (!result) {
                        return new Promise(async (resolve) => {
                            resolve(new Blob[``]()) // якшо результату нема, то пустий Блоб повертаємо
                        })
                    }

                    const copyText = item.message
                        return new Promise(async (resolve) => {
                            resolve(new Blob([copyText])) // якщо все ок, то копіюємо текст і зберігаємо в блоб
                        })
                    }),

                })
                try {
                    // Now, we can write to the clipboard in Safari
                    navigator.clipboard.write([clipboardItem]) // записуємо в буфер
                    this.$toast.success(this.$t('Copied'));
                } catch {
                    this.$toast.error(`Fucking mistake, contact Borys`);
                }

                return
            }
            try {
                navigator.clipboard.writeText(item);
                this.$toast.success(this.$t('Copied')+ ` - ` + item);
            } catch {
                this.$toast.error(`Fucking mistake, обратитесь к Максу`);
            }
        },
        mutatePriority(e){
            return mutatePriority(e)
        },
    }
}
</script>