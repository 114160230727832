<template>
    <!-- модальное окно просмотра заявки -->
    <modal :key="componentKey" :ifPrevopen="ifPrevopen" :ifNextopen="ifNextopen" @prevOpen="prevOpen" @nextOpen="nextOpen" @close="closeModal">
        <template v-slot:title>{{ form.status == 'new' ? $t('newApplication') : $t('Application') }} #{{ this.form.requestId }}</template>
        <template v-slot:status>
            <span v-if="form.action" :class="`badge badge ${this.mutateType(this.form.action).color} align-middle fs-14`"><i :class="this.mutateType(this.form.action).icon"></i></span>
            <span :style="form.action ? `margin-left: 5px;` : ''" :class="`badge badge ${this.mutateStatus(this.form.status, this.form).color} align-middle fs-14`">{{this.mutateStatus(this.form.status, this.form).name}}</span>
        </template>
        <template v-slot:head-button>
            <div class="dropdown" v-if="(form.status !== 'deleted' && (perms[103] || perms[102] || perms[105]) && (form.inJobWorkerId == this.user.userid || form.inJobWorkerId == null)) || this.form.status =='callNow'">
                <button class="btn btn-ghost-secondary btn-icon btn-sm fs-16" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="bx bx-dots-vertical-rounded align-bottom"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                    <button class="dropdown-item"  v-if='((perms[103] || perms[2000]) && (form.status === "wait_comment" || form.status === "jobs") && (form.inJobWorkerId == this.user.userid || form.inJobWorkerId == null))' @click="showModalClose = true">{{ $t('close') }}</button> <!-- закрыть заявку -->
                    <button class="dropdown-item" v-if="(form.status === 'new' && (perms[105] === true || perms[2000] === true))" @click="this.$emit('edit', this.form)">{{ $t('Edit') }}</button> <!-- редактировать заявку -->
                    <button class="dropdown-item" v-if="((form.status === 'jobs' || form.status === 'delayed' || form.status === 'wait_comment') && (form.inJobWorkerId == this.user.userid || form.inJobWorkerId == null)) || this.form.status=='callNow'" @click="showModalDelay = true">{{ $t('postpone')}}</button>  <!-- отложить заявку -->
                    <button class="dropdown-item" v-if="((form.status === 'jobs'  || form.status === 'wait_comment') && (perms[102] || perms[2000]))" @click="showModalTransfer = true">{{ $t('Submit')}}</button> <!-- передать заявку -->
                    <button class="dropdown-item" v-if="(perms[103] || perms[2000])" @click="dialogshow = true">{{ $t('Delete') }}</button> <!-- удалить заявку -->
                    <button class="dropdown-item" v-if="((perms[105] || perms[2000]) && form.status !== 'canceled' && this.form.type !== 'request' && form.status !== 'closed' && form.status !== 'callNow' && form.type=='requestByCall')" @click="callNow(this.form.requestId)">{{ $t('CallNow') }}</button>
                </div>
            </div>
        </template>
        <template v-slot:body>
            <b-alert v-if="form.status == 'transfered'" show variant="warning" class="text-center">
                <h5 class="alert-heading" v-if="this.user.userid != form.transferWorkerId">
                    <b>{{ this.form.inJobWorkerName }} {{ $t('submittedRequest') }} {{ nameWorker(form.transferWorkerId) }}</b>
                </h5>
                <h5 class="alert-heading" v-else>
                    <b>{{ this.form.inJobWorkerName }} {{ $t('sentYouRequest') }}</b>
                </h5>
            </b-alert>
            <b-row>
                <b-col lg="4">
                    <aboutBlock 
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>

                <b-col lg="4">
                    <clientBlock
                        :obj="this.form"
                        @getClient="this.getClient"
                    />
                </b-col>

                <b-col lg="4" v-if="form.inJobWorkerId != null">
                    <jobsBlock
                        :obj="this.form"
                    />
                </b-col>

                <b-col lg="4" v-if="form.closeWorkerId != null">
                    <closedBlock 
                        :obj="this.form"
                    />
                </b-col>
                <b-col lg="4" v-if="form.cancelWorkerId != null">
                    <canceledBlock 
                        :obj="this.form"
                    />
                </b-col>
            </b-row>
                
        </template>
        <template v-slot:footer-bottom>
                <!-- <button 
                    type="button"
                    @click="this.getClient(this.form.userPhone)"
                    :class="`btn btn-info ss waves-effect waves-light`">
                    {{ $t('infoAboutClient')}}
                </button> -->

                <button 
                    type="button" 
                    v-if="(form.status != 'new' && (perms[103] || perms[2000]) && form.inJobWorkerId == this.user.userid)"
                    @click="showModalCancel = true" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('cancel') }}
                </button> <!-- отмена заявки -->

                <button 
                    type="button" 
                    v-if="(form.status != 'new' && (perms[103] || perms[2000]) && form.inJobWorkerId == this.user.userid)"
                    @click="showModalRepCancel = true" 
                    class="btn btn-danger r waves-effect waves-light">
                    {{ $t('cancelReport') }} 
                </button> <!-- додати репорт на скасування заявки -->

                <button 
                    type="button" 
                    v-if="this.form.inJobWorkerName === this.user.name && (this.form.status=='jobs' || this.form.status=='wait_comment')"
                    @click="showModalClose = true"
                    class="btn btn-success r waves-effect waves-light">
                    {{ $t('done') }}
                </button> <!-- выполнить/закрыть заявку -->

                <button 
                    type="button"
                    v-if="perms[104] && (form.status == 'new'  || this.form.status=='callNow' || this.form.status=='delayed')"
                    @click="toWork()"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('takeToWork') }}
                </button> <!-- взять в работу заявку -->
                
                <button 
                    type="button"
                    v-if="perms[114] && form.status == 'transfered' && form.transferWorkerId == this.user.userid"
                    @click="rejectTransfer()"
                    :class="`btn btn-danger ss waves-effect waves-light`">
                    {{ $t('Refusal') }}
                </button> <!-- відмовитись від передачі заявки -->

                <button 
                    type="button"
                    v-if="perms[104] && form.status == 'transfered' && form.transferWorkerId == this.user.userid"
                    @click="confirmTransfer()"
                    :class="`btn btn-success ss waves-effect waves-light`">
                    {{ $t('ConfirmTransfer') }}
                </button> <!-- підтвердити передачу -->

        </template>
    </modal>
</template>

<script>
import modal from '@/components/modal/modal-lg'
import aboutBlock from './blocks/about'
import clientBlock from './blocks/client'
import jobsBlock from './blocks/jobs'
import viewboxnew from '@/views/accounts/allAccounts/viewnew/index.vue'
import { SuperAdmin, Clients } from "@/API.js"
import { storeS } from '@/store.js'
import { mutateStatus, mutateTypeRequest } from '@/usabilityScripts/globalMutate.js'
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import dialogBox from '@/components/modal/dialog'

let apiServe = new SuperAdmin();
let apiClients = new Clients();

export default ({
    data(){
        return {
        }
    },
    props: [],
    components: {
        modal,
        dialogBox,
        viewboxnew,
        aboutBlock,
        clientBlock,
        jobsBlock,
    },
    created(){        
        
    },
    methods: {
        nameWorker(item){
            return nameWorker(item)
        },
        prevOpen(){
            //пред заявка
            this.$emit('close');
            this.$emit('prevOpen', this.form.requestId)
        },
        nextOpen(){
            //след заявка
            this.$emit('close');
            this.$emit('nextOpen', this.form.requestId)
        },
        updateCard(){
            //обновить данные
            apiApp.getRequest(this.form.requestId).then(result => {
                if(result.status == 'done'){
                    this.form = result.data;
                    this.componentKey += 1
                }
            })
        },
        toWork: function(e){
            //взять в работу
            if(this.perms['104']== true || this.perms['2000'] == true) {
                apiApp.addRequestInJob(this.form.requestId).then(res => {
                    if(res.status === 'done') {
                        // this.$emit('toWork') //инициализация события
                        // this.updateCard();
                        this.$toast.success(this.$t('statusInWork'));
                        this.getClient(this.form.userPhone);
                    }   
                })
            }
        },
        mutateData(month) {
            switch(month){
                case "Jan": 
                    month = '1'
                    break;
                case "Feb": 
                    month = '2'
                    break;
                case "Mar": 
                    month = '3'
                    break;
                case "Apr": 
                    month = '4'
                    break;
                case "May": 
                    month = '5'
                    break;
                case "Jun": 
                    month = '6'
                    break;
                case "Jul": 
                    month = '7'
                    break;
                case "Aug": 
                    month = '8'
                    break;
                case "Sep": 
                    month = '9'
                    break;
                case "Oct": 
                    month = '10'
                    break;
                case "Nov": 
                    month = '11'
                    break;
                case "Dec": 
                    month = '12'
                    break;
            }
            return month //по месяцам
        },
    },
    mounted() {

    },
    computed:{
        // perms() {
        //     return storeS.perms
        // },
        user() {
            return storeS.userbase
        },
        workers(){
            return storeS.workers //база работников
        }
    }
})
</script>


<style scoped>
.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.c_block:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}

.copybtn:checked {
    width: 500px;
}
</style>